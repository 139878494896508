<script>
import _ from "lodash";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import exportExcel from "../../helpers/exportExcel.js";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../app.config.json";
import Swal from "sweetalert2";
import animationData from "@/components/widgets/msoeawqm.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";
import Lottie from "@/components/widgets/lottie.vue";

export default {
  page: {
    title: "Request for Quotes",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Request for Quotes",
      items: [
        {
          text: "List",
          href: "/",
        },
        {
          text: "Request for Quotes",
          active: true,
        },
      ],
      config: {
        mode: "range",
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        altInput: true,
        dateFormat: "d M, Y",
      },
      date: null,
      page: 1,
      perPage: 9,
      pages: [],
      value: null,
      searchQuery: null,

      defaultOptions: {
        animationData: animationData,
      },
      defaultOptions1: {
        animationData: animationData1,
      },
      table_options: {
        current_page: 1,
        page_size: 10,
        search_text: "",
        sort: { column: "", direction: "" },
      },
      meta: {},
      rfqList: [],
      selectedRfqIDs: [],
    };
  },
  loading: false,
  components: {
    Layout,
    PageHeader,
    Multiselect,
    lottie: Lottie,
    flatPickr,
  },
  methods: {
    //get all products
    getAllRFQs() {
      this.loading = true;
      this.$store
        .dispatch("rfqs/getAllRFQs", this.table_options)
        .then((res) => {
         
          this.rfqList = res.data;
          this.meta.total = res.total;
          this.meta.from = res.from;
          this.meta.last_page = res.last_page;
        })
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
    // EXPORT PRODUCTS
    exportRFQs() {
      this.$store.dispatch("products/exportProductsExcel").then((res) => {
        exportExcel(res, "rfqs-list.xlsx");
      });
    },
    // child checkboxes
    handleChildCheckBoxChange(payload) {
      if (this.selectedRfqIDs.includes(payload.data.id)) {
        let index = this.selectedRfqIDs.indexOf(payload.data.id);
        this.selectedRfqIDs.splice(index, 1);
      } else {
        this.selectedRfqIDs.push(payload.data.id);
      }
      var checkchild = document.querySelectorAll(
        '.form-check-all input[type="checkbox"]'
      );
      checkchild.forEach(function (checkbox) {
        checkbox.onclick = function () {
          if (checkbox.checked == true) {
            checkbox.closest("tr").classList.add("table-active");
          } else {
            checkbox.closest("tr").classList.remove("table-active");
          }
        };
      });
    },
    // All checkbox
    handleCheckAllRFQChange() {
      let checkboxes = document.querySelectorAll(
        '.form-check-all input[type="checkbox"]'
      );
      if (this.checkAll) {
        this.selectedRfqIDs = [];
        this.rfqList.forEach((data) => this.selectedRfqIDs.push(data.id));
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = true;
          checkbox.closest("tr").classList.add("table-active");
        });
      } else {
        this.selectedRfqIDs = [];
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = false;
          checkbox.closest("tr").classList.remove("table-active");
        });
      }
    },
    //delete rfq data
    deleterfq(event) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('rfqs/deleteRFQ', event)
            .then(() => {
              this.getAllRFQs();
              Swal.fire("Deleted!", "RFQ deleted successfully.", "success");
            })
            .catch((er) => {
              console.log(er);
            });
        }
      });
    },
    deleteMultiple() {
      if (
        typeof this.selectedRfqIDs !== "undefined" &&
        this.selectedRfqIDs.length > 0
      ) {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to delete this?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch(`rfqs/deleteMultipleRFQ`, this.selectedRfqIDs)
              .then(() => {
                this.getAllRFQs();
                document.getElementById("checkAll").checked = false;
                var itemss = document.getElementsByName("chk_child");
                itemss.forEach(function (ele) {
                  if (ele.checked == true) {
                    ele.checked = false;
                    ele.closest("tr").classList.remove("table-active");
                  }
                });
                Swal.fire("Deleted!", "RFQs deleted successfully.", "success");
              })
              .catch((er) => {
                console.log(er);
              });
          }
        });
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    debounceInput: _.debounce(function (e) {
      this.table_options.search_text = e.target.value;
      this.$store
        .dispatch("rfqs/getAllRFQs", this.table_options)
        .then((response) => {
          this.productData = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    }, 500),

    handlePageChange(e) {
      this.table_options.current_page = e;
      this.$store
        .dispatch("rfqs/getAllRFQs", this.table_options)
        .then((response) => {
          this.rfqList = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    },
  },
  mounted() {
    this.getAllRFQs();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="rfqList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">RFQs</h5>
              <div class="flex-shrink-0">
                <button
                  class="btn btn-soft-danger me-1"
                  @click="deleteMultiple"
                >
                  <i class="ri-delete-bin-2-line"></i>
                </button>
                <router-link to="/request-for-quotes/add" class="btn btn-danger"
                  ><i class="ri-add-line align-bottom me-1"></i> Add New
                  RFQ</router-link
                >
              </div>
            </div>
          </div>
          <div
            class="card-body bg-soft-light border border-dashed border-start-0 border-end-0"
          >
            <form>
              <div class="row g-3">
                <div class="col-xxl-5 col-sm-12">
                  <div class="search-box">
                    <input
                      type="text"
                      class="form-control search bg-light border-light"
                      placeholder="Search for customer, email, country, status or something..."
                    />
                    <i class="ri-search-line search-icon"></i>
                  </div>
                </div>
                <!--end col-->
                <div class="col-xxl-3 col-sm-4">
                  <flat-pickr
                    v-model="date"
                    :config="config"
                    class="form-control bg-light border-light"
                    placeholder="Select date"
                  ></flat-pickr>
                </div>
                <!--end col-->
                <div class="col-xxl-3 col-sm-4">
                  <div class="input-light">
                    <Multiselect
                      class="form-control"
                      v-model="value"
                      :close-on-select="true"
                      :searchable="true"
                      :create-option="true"
                      :options="[
                        { value: 'all', label: 'all' },
                        { value: 'Unpaid', label: 'Unpaid' },
                        { value: 'Paid', label: 'Paid' },
                        { value: 'Cancel', label: 'Cancel' },
                        { value: 'Refund', label: 'Refund' },
                      ]"
                    />
                  </div>
                </div>
                <!--end col-->

                <div class="col-xxl-1 col-sm-4">
                  <button
                    type="button"
                    class="btn btn-primary w-100"
                    onclick="SearchData();"
                  >
                    <i class="ri-equalizer-fill me-1 align-bottom"></i> Filters
                  </button>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </form>
          </div>
          <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table
                  class="table align-middle table-nowrap"
                  id="invoiceTable"
                  v-if="rfqList.length > 0"
                >
                  <thead class="text-muted">
                    <tr>
                      <th scope="col" style="width: 50px">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="checkAll"
                            value="option"
                            v-model="checkAll"
                            @change="handleCheckAllRFQChange"
                          />
                        </div>
                      </th>
                      <th class="sort text-uppercase" data-sort="invoice_id">
                        Client Code
                      </th>
                      <th class="sort text-uppercase" data-sort="customer_name">
                        Phone
                      </th>
                      <th class="sort text-uppercase" data-sort="customer_name">
                        Email
                      </th>
                      <th class="sort text-uppercase" data-sort="customer_name">
                        Country
                      </th>
                      <th class="sort text-uppercase" data-sort="customer_name">
                        Customer
                      </th>
                      <th class="sort text-uppercase" data-sort="action">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of rfqList" :key="index">
                      <th scope="row">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="chk_child"
                            @change="handleChildCheckBoxChange({data:item, index:index})"
                          />
                        </div>
                      </th>
                      <td class="id">
                        <router-link
                          to="/invoices/detail"
                          class="fw-medium link-primary"
                        >
                          {{ item.client && item.client.code }}
                        </router-link>
                      </td>
                      <td class="customer_name">
                        <div class="d-flex align-items-center">
                          {{ item.client && item.client.phone }}
                        </div>
                      </td>
                      <td class="customer_name">
                        <div class="d-flex align-items-center">
                          {{ item.client && item.client.email }}
                        </div>
                      </td>
                      <td class="customer_name">
                        <div class="d-flex align-items-center">
                          {{ item.client && item.client.country }}
                        </div>
                      </td>
                      <td class="customer_name">
                        <div class="d-flex align-items-center">
                          {{ item.client && item.client.name }}
                        </div>
                      </td>
                      <td>
                        <div class="dropdown">
                          <button
                            class="btn btn-soft-secondary btn-sm dropdown"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <router-link
                                class="dropdown-item"
                                :to="'/request-for-quotes-details/' + item.id"
                              >
                                <i
                                  class="ri-eye-fill align-bottom me-2 text-muted"
                                ></i>
                                View</router-link
                              >
                            </li>
                            <li>
                              <router-link class="dropdown-item"
                               :to="`/request-for-quotes/${item.id}/update`"
                                ><i
                                  class="ri-pencil-fill align-bottom me-2 text-muted"
                                ></i>
                                Edit</router-link
                              >
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="javascript:void(0);"
                                ><i
                                  class="ri-download-2-line align-bottom me-2 text-muted"
                                ></i>
                                Download</a
                              >
                            </li>
                            <li class="dropdown-divider"></li>
                            <li>
                              <a
                                class="dropdown-item remove-item-btn"
                                style="cursor:pointer"
                                @click="deleterfq(item)"
                              >
                                <i
                                  class="ri-delete-bin-fill align-bottom me-2 text-muted"
                                ></i>
                                Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="noresult d-block" v-else>
                  <div class="text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#121331,secondary:#08a88a"
                      :options="defaultOptions"
                      :height="75"
                      :width="75"
                    />
                    <h5 class="mt-2">Sorry! No Result Found</h5>
                    <p class="text-muted mb-0">
                      We've searched more than 150+ Orders We did not find any
                      orders for you search.
                    </p>
                  </div>
                </div>
              </div>

               <div 
                  @click="handlePageChange(table_options.current_page)"
                  class="d-flex mt-4 mx-3 align-items-center justify-content-end"
                >
                  <b-pagination
                    v-model="table_options.current_page"
                    :total-rows="meta.total"
                    :per-page="table_options.page_size"
                    prev-text="Prev"
                    next-text="Next"
                    prev-class="prev"
                    next-class="next"
                    class=" pagination-wrap hstack gap-2"
                  >
                    <template #prev-text>
                      <div class="page-item shadow-sm pagination-prev disabled">
                        Previous
                      </div>
                    </template>
                    <template #next-text>
                      <div class="page-item shadow-sm  pagination-next">Next</div>
                    </template>
                  </b-pagination>
                </div>
            </div>

            <!-- Modal -->
            <div
              class="modal fade flip"
              id="deleteOrder"
              tabindex="-1"
              aria-labelledby="deleteOrderLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body p-5 text-center">
                    <lottie
                      class="avatar-xl"
                      colors="primary:#4b38b3,secondary:#f06548"
                      :options="defaultOptions1"
                      :height="90"
                      :width="90"
                    />
                    <div class="mt-4 text-center">
                      <h4>You are about to delete a order ?</h4>
                      <p class="text-muted fs-15 mb-4">
                        Deleting your order will remove all of your information
                        from our database.
                      </p>
                      <div class="hstack gap-2 justify-content-center remove">
                        <button
                          class="btn btn-link link-success fw-medium text-decoration-none"
                          data-bs-dismiss="modal"
                        >
                          <i class="ri-close-line me-1 align-middle"></i> Close
                        </button>
                        <button class="btn btn-danger" id="delete-record">
                          Yes, Delete It
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end modal -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

